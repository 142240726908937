import React from 'react';
import ReactDOM from 'react-dom';

import './css/style.scss';
import './css/style1.scss';
import './index.css';

import App from './App';

import './i18n.js';

import { Provider } from 'react-redux';
import store from 'store/index';

//import { QueryClient } from '@tanstack/react-query';

//import reportWebVitals from './reportWebVitals';

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker
			.register('/service-worker.js')
			.then(registration => {
				console.log('Service Worker registered with scope:', registration.scope);
			})
			.catch(error => {
				console.error('Service Worker registration failed:', error);
			});
	});
}

// START PWA Deferred / Custom action installation script
var deferredPrompt;
var divInstall;

document.addEventListener("DOMContentLoaded", function (event) {
	console.log('PWA Deferred', 'DOMContentLoaded', event);

	const butInstall = document.getElementById("btPWAInstall");
	divInstall = document.getElementById("divPWAInstall");
	deferredPrompt = null;

	butInstall.addEventListener('click', async () => {
		console.log('PWA Deferred', 'Install Button Clicked');

		if (!deferredPrompt) {
			// The deferred prompt isn't available.
			return;
		}
		// Show the install prompt
		deferredPrompt.prompt();
		// Wait for the user to respond to the prompt
		const { outcome } = await deferredPrompt.userChoice;
		// Optionally, send analytics event with outcome of user choice
		console.log('PWA Deferred', 'User response to the install prompt', outcome);
		// We've used the prompt, and can't use it again, throw it away
		deferredPrompt = null;
		// Hide the install button.
		divInstall.classList.toggle('hidden', true);
	});
});

window.addEventListener('beforeinstallprompt', (e) => {
	// Prevent the mini-infobar from appearing on mobile
	e.preventDefault();
	console.log('PWA Deferred', 'beforeinstallprompt', e);
	// Stash the event so it can be triggered later.
	deferredPrompt = e;
	// Remove the 'hidden' class from the install button container.
	divInstall.classList.toggle('hidden', false);
});

window.addEventListener('appinstalled', () => {
	// Hide the install button.
	divInstall.classList.toggle('hidden', true);
	// Clear the deferredPrompt so it can be garbage collected
	deferredPrompt = null;
	// Optionally, send analytics event to indicate successful install
	console.log('PWA was installed');
});
// END PWA Deferred / Custom action installation script

//const queryClient = new QueryClient();

const app = (
	<React.StrictMode>
		{/* <QueryClientProvider client={queryClient}> */}
		<Provider store={store}>
			<App />
		</Provider>
		{/* </QueryClientProvider> */}
		<div id="divPWAInstall" className="hidden">
			<button id="btPWAInstall" type="button">Install PWA</button>
		</div>
	</React.StrictMode>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
