// If there the product bought is a variant we return ProductId + ProductDimensionId
export const composeCPProductId = (item, cartItem = false) => {
	if (cartItem) {
		if (item.dimension) {
			const dimension = item.product.dimensions.find(x => x.cval);
			console.log(dimension);

			if (dimension?.PrevId) {
				return dimension.PrevId;
			}

			if (dimension?.cval) {
				return item.pid + '-' + dimension.cval;
			}
		}

		return item.pid;
	}

	const dimension = item.dimensions.find(x => x.cval);

	if (dimension?.PrevId) {
		return dimension.PrevId;
	}

	if (dimension?.cval) {
		return item.id + '-' + dimension.cval;
	}
};
