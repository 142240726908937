import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginAction } from 'store/auth/actions';

import Button from 'components/common/Button';
import { notifyError } from 'components/common/ToastMessages';
import eyeOffIcon from '../../images/eye-off.svg';
import eyeIcon from '../../images/eye.svg';

const LoginForm = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [isHuman, setHuman] = useState(true);
	const [visiblePassword, setVisiblePassword] = useState(false);
	const dispatch = useDispatch();

	const handleSubmit = () => {
		if (email == null || email.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (pass == null || pass.length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (process.env.REACT_APP_ENV === 'production') {
			if (!isHuman) {
				notifyError('Please complete the CAPTCHA to proceed');
				return;
			}
		}

		let args = {
			usr: email,
			psw: pass
		};

		dispatch(loginAction(args));
	};

	return (
		<>
			<div className="input-container">
				<input
					className="login-email"
					type="text"
					placeholder="Email"
					onChange={e => setEmail(e.target.value)}
					value={email}
				/>
				<div className="login-password-input">
					<input
						className="login-password"
						type={visiblePassword ? 'text' : 'password'}
						placeholder={t('password_title')}
						onChange={e => setPass(e.target.value)}
						required
						value={pass}
					/>
					<span
						id="togglePassVisibillity"
						className="pass-visibillity-icon"
						onClick={() => setVisiblePassword(!visiblePassword)}>
						<img src={visiblePassword ? eyeOffIcon : eyeIcon} alt="eye-show-pass" />
					</span>
				</div>
			</div>

			<div className="remember-forgot">
				<label className="remember-pwd">
					<input type="checkbox" name="remember-pwd-checkbox" />
					<span className="iremember-pwd-label">{t('remember_me')}</span>
				</label>

				<div className="forgot-password">
					<Link to="/resetpasswordrequest">{t('reset_password_title')}</Link>
				</div>
			</div>

			<div className="captcha-wrapper">
				{email.length > 0 && pass.length > 0 ? (
					<ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY} onChange={() => setHuman(true)} />
				) : null}
			</div>

			<div className="login-button">
				<Button color="secondary" actionOnClick={handleSubmit}>
					{t('sign_in_title')}
				</Button>
			</div>
		</>
	);
};

LoginForm.propTypes = {};

export default LoginForm;
