import PropTypes from 'prop-types';
import React from 'react';

import i18n from 'i18n';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import logoPNG from '../../images/logo.png';

const PageHeaderMeta = props => {
	let location = useLocation();
	const isProduction = process.env.REACT_APP_ENV === 'production';

	const title = props.page_title ? props.page_title + ' - Sportistas' : 'Sportistas';
	const type = props.type ? props.type : 'website';
	const url = 'https://www.sportistas.com' + location.pathname;
	const image = props.image ? props.image : window.location.origin + logoPNG;
	const locale = i18n.language === 'el' ? 'el_GR' : 'en_US';

	const renderMetaKeywords = () => {
		if (props.meta_keywords != null) {
			return <meta name="keywords" content={props.meta_keywords} />;
		}

		return null;
	};

	const renderMetaDescription = () => {
		if (props.meta_description != null) {
			return <meta name="description" content={props.meta_description} />;
		}

		return null;
	};
	const renderOpenGraphDescription = () => {
		if (props.meta_description != null) {
			return <meta property="og:description" content={props.meta_description} />;
		}

		return null;
	};

	const renderProductPrice = () => {
		if (props.product_price != null) {
			return <meta property="product:price:amount" content={props.product_price} />;
		}

		return null;
	};
	const renderProductCurrency = () => {
		if (props.product_price != null) {
			return <meta property="product:price:currency" content="EUR" />;
		}

		return null;
	};

	return (
		<HelmetProvider>
			<Helmet>
				<meta charset="utf-8" />
				{/*<meta name="robots" content="noindex" />*/}
				<title>{title}</title>
				<meta property="og:site_name" content="Sportistas" />
				<meta property="og:title" content={title} />
				<meta property="og:type" content={type} />
				<meta property="og:url" content={url} />
				<meta property="og:image" content={image} />
				<meta property="og:image:url" content={image} />
				<meta property="og:locale" content={locale} />
				{!isProduction && <meta name="robots" content="noindex, nofollow"></meta>}
				{isProduction && <meta name="google-site-verification" content="sRsNwx5O4HLQMIpMYGVJyT48Eq2kEKorH4kKWlzqd7g" />}

				<link rel="canonical" href={url} />

				{renderMetaKeywords()}
				{renderMetaDescription()}
				{renderOpenGraphDescription()}
				{renderProductPrice()}
				{renderProductCurrency()}
			</Helmet>
		</HelmetProvider>
	);
};

PageHeaderMeta.propTypes = {
	page_title: PropTypes.string,
	meta_keywords: PropTypes.string,
	meta_description: PropTypes.string,
	type: PropTypes.string,
	image: PropTypes.string,
	product_price: PropTypes.number
};

export default PageHeaderMeta;
