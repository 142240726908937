import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import ProductCard from 'components/common/ProductCard/ProductCard';
import { SearchResultsLoader } from 'components/common/SkeletonLoaders';
import Search from 'components/layout/header/Search';
import useApplyFilters from 'core/filters/hooks/useApplyFilters';
import { useLocation, useNavigate } from 'react-router';
import NoResults from './NoResults';

const SearchScreen = ({
	searchTerm,
	setSearchTerm,
	searchResults,
	setSearchResults,
	searchTransition,
	setSearchTransition,
	loading,
	setLoading
}) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const nodeRef = useRef(null);
	const { setSorting, setViewPerPage } = useApplyFilters();

	useEffect(() => {
		setSearchTransition(false);
		setSearchTerm('');
	}, [location]);

	return (
		<Transition nodeRef={nodeRef} in={searchTransition} timeout={300} unmountOnExit>
			<div id="search-screen" className="search-screen">
				<div className="search-container">
					<Search
						searchTerm={searchTerm}
						setSearchTerm={setSearchTerm}
						searchResults={searchResults}
						setSearchResults={setSearchResults}
						setSearchTransition={setSearchTransition}
						showOnSearchScreen={true}
						loading={loading}
						setLoading={setLoading}
					/>
				</div>

				{loading ? (
					<SearchResultsLoader />
				) : (
					<div className="search-results-container">
						{/* <CatalogFilters
							filters={filters}
							filtersModalVisible={filtersModalVisible}
							setFiltersModalVisible={setFiltersModalVisible}
							displayAsModal={true}
						/> */}
						{!loading && searchResults.prds.length === 0 && searchTerm.trim().length > 0 && <NoResults />}
						{!loading && searchResults.prds.length > 0 && (
							<>
								<h3 className="search-results-title"> {t('products')} </h3>
								<div className="search-results">
									{searchResults?.prds?.map((product, index) => (
										<div key={index} onClick={() => setSearchTransition(false)}>
											<ProductCard product={product} />
										</div>
									))}
								</div>
							</>
						)}

						{/* <div className="show-all-results">
							<Button color="secondary" actionOnClick={() => navigate(`/search?searchquery=${searchTerm}`)}>
								Show All Results
							</Button>
						</div> */}
					</div>
				)}
			</div>
		</Transition>
	);
};

SearchScreen.propTypes = {
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	searchResults: PropTypes.object,
	setSearchResults: PropTypes.func,
	searchTransition: PropTypes.bool,
	setSearchTransition: PropTypes.any,
	loading: PropTypes.bool,
	setLoading: PropTypes.func
};

export default SearchScreen;
