import { changeLanguage } from 'common/helper';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import englishicon from '../../images/lang-en.svg';
import greekicon from '../../images/lang-gr.svg';

const ChangeLanguage = ({ styles }) => {
	const { t, i18n } = useTranslation();
	const location = useLocation();
	const { pathname } = location;

	return (
		<>
			<div id="change-language" onClick={() => changeLanguage(pathname, i18n)} style={styles}>
				{localStorage.getItem('I18N_LANGUAGE') == 'el' ? (
					<img className="country" src={greekicon} alt="Language" />
				) : (
					<img className="country" src={englishicon} alt="Language" />
				)}

				<span>{localStorage.getItem('I18N_LANGUAGE') == 'el' ? 'GR' : 'EN'}</span>
				{/* <img className="dropdown" src={arrowrighticon} alt="Man Category Image"/> */}
			</div>
		</>
	);
};

ChangeLanguage.propTypes = {
	styles: PropTypes.object
};

export default ChangeLanguage;
