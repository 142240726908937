import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { dropDownSelectStylesGender, focusSelect, unFocusSelect } from 'common/selects.js';
import Select from 'react-select';

import Button from 'components/common/Button';
import { notifyError } from 'components/common/ToastMessages';
import { validateGuestUserEmail } from 'core/checkout/utils/checkout_step_1';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { registerCustomerAction } from 'store/actions';
import eyeOffIcon from '../../images/eye-off.svg';
import eyeIcon from '../../images/eye.svg';

const RegisterForm = ({ setRegisterFormActive }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();

	const [showBackButton, setShowBackButton] = useState(true);
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [mobile, setMobile] = useState('');
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [visiblePassword, setVisiblePassword] = useState(false);
	const [gender, setGender] = useState(1);
	const [birthDate, setBirthDate] = useState();
	const [acceptPromoEmails, setAcceptPromoEmails] = useState(false);
	const [acceptPolicy, setAcceptPolicy] = useState(false);
	const [isHuman, setHuman] = useState(true);

	const authError = useSelector(state => state.registerReducer.error);
	const successMessage = useSelector(state => state.registerReducer.message);

	useEffect(() => {
		if (location.pathname == '/login') {
			setShowBackButton(false);
		}

		if (authError.length > 0) {
			notifyError(t('email_already_exists'), () => {});
		}
	}, [authError]);

	useEffect(() => {
		if (successMessage.length > 0) {
			if (successMessage === 'Google Registration completed' || successMessage === 'Facebook Registration completed')
				return;

			window.location.href = '/registrationcheckemail';
		}
	}, [successMessage]);

	const handleSubmit = () => {
		if (firstname == null || firstname.trim().length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}
		if (lastname == null || lastname.trim().length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}
		if (mobile == null || mobile.trim().length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}
		if (mobile.trim().length < 7 || mobile.trim().length > 18) {
			notifyError(t('invalid_mobile'));
			return;
		}
		if (email == null || email.trim().length == 0) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (!validateGuestUserEmail(email)) {
			notifyError(t('validation_email'));
			return;
		}

		if (pass.trim().length < 6) {
			notifyError(t('password_strong'));
			return;
		}

		if (gender !== 1 && gender !== 2) {
			setGender(1);
		}

		if (!birthDate) {
			notifyError(t('complete_all_fields'));
			return;
		}

		if (birthDate.length !== 10) {
			notifyError(t('validation_birth'));
			return;
		}

		if (!isHuman) {
			notifyError('Please complete the CAPTCHA to proceed');
			return;
		}

		if (!acceptPolicy) {
			notifyError(t('contact_complete_privacy'));
			return;
		}

		const customer = {
			fn: firstname,
			ln: lastname,
			mob: mobile,
			eml: email,
			psw: pass,
			gd: gender,
			rnl: acceptPromoEmails,
			at: acceptPolicy,
			dob: birthDate
		};

		dispatch(registerCustomerAction(customer));
	};

	return (
		<>
			{showBackButton && (
				<div className="go-back" onClick={() => setRegisterFormActive(false)}>
					<span>Πίσω</span>
				</div>
			)}
			<div className="register-inputs">
				<input
					type="text"
					placeholder={t('first_name')}
					value={firstname}
					onChange={e => setFirstname(e.target.value)}
				/>

				<input type="text" placeholder={t('last_name')} value={lastname} onChange={e => setLastname(e.target.value)} />

				<PhoneInput
					className={'register-phone-input'}
					country={'gr'}
					countryCodeEditable={false}
					specialLabel=""
					placeholder="Mobile"
					value={mobile}
					onChange={(v, c, e, formattedValue) => {
						setMobile(formattedValue);
					}}
				/>

				<input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />

				<div className="register-password-input">
					<input
						className="register-password"
						type={visiblePassword ? 'text' : 'password'}
						placeholder={t('password_title')}
						required
						value={pass}
						onChange={e => setPass(e.target.value)}
					/>
					<span
						id="togglePassVisibillity"
						className="pass-visibillity-icon"
						onClick={() => setVisiblePassword(!visiblePassword)}>
						<img src={visiblePassword ? eyeOffIcon : eyeIcon} alt="eye-show-pass" />
					</span>
					{/* {pass != null && pass.trim().length > 0 && (
						<PasswordStrengthBar
							className="custom-pass"
							password={pass}
							barColors={['#ddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
							minLength={6}
						/>
					)} */}
				</div>

				<Select
					id="regionSelect"
					className="gender-select"
					placeholder={t('gender')}
					options={[
						{ label: t('men'), value: '1' },
						{ label: t('women'), value: '2' }
					]}
					onMenuClose={() => {
						unFocusSelect('regionSelect');
					}}
					onMenuOpen={() => {
						focusSelect('regionSelect');
					}}
					onChange={e => setGender(e.value)}
					required
					styles={dropDownSelectStylesGender}
				/>

				<div className="birth-date">
					<div className="form-input text-label actived">
						<label htmlFor="date">{t('date_of_birth')}</label>
						<input
							type="date"
							name="date"
							defaultValue={birthDate}
							onChange={e => setBirthDate(e.target.value)}
							placeholder="birthdate"
						/>
					</div>
				</div>
			</div>

			<div className="register-checkboxes">
				<label className="accept-send-email">
					<input
						type="checkbox"
						checked={acceptPromoEmails}
						onChange={e => setAcceptPromoEmails(e.target.checked)}
						name="accept-send-email-input"
					/>
					<span className="accept-send-email-label">{t('accept_newsletter')}</span>
				</label>
				<label className="accept-policy">
					<input
						type="checkbox"
						checked={acceptPolicy}
						onChange={e => setAcceptPolicy(e.target.checked)}
						name="accept-policy-input"
					/>
					<span className="accept-policy-label">{t('accept_terms')}</span>
				</label>
			</div>

			<div className="register-button">
				<Button color="secondary" actionOnClick={handleSubmit}>
					{t('register_title')}
				</Button>
			</div>
		</>
	);
};

RegisterForm.propTypes = {
	setRegisterFormActive: PropTypes.func
};

export default RegisterForm;
