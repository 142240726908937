import useWindowSize from 'components/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { api_getPersonalizedProductsIdsByCategoryId, api_getProductsByIds } from '../../backend/api_calls';
import PersonalisedProducts from '../../backend/personalised_products';

import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { viewItemListGTAG4Event } from 'common/gtag4';
import { attachIndexesToProducts } from 'common/helper';
import 'swiper/css';
import 'swiper/css/pagination';
import ProductCard from './ProductCard/ProductCard';

const ProductsPersonalised = ({ title, excludedProductId, categoryId }) => {
	const { width } = useWindowSize();
	const [products, setProducts] = useState([]);
	const [hasSentEvent, setHasSentEvent] = useState(false);

	let productsIds = PersonalisedProducts.getItems();
	if (excludedProductId !== undefined) productsIds = productsIds.filter(value => value != excludedProductId);

	useEffect(() => {
		if (!hasSentEvent && products.length > 0) {
			viewItemListGTAG4Event(attachIndexesToProducts(products), title);
			setHasSentEvent(true);
		}
	}, [hasSentEvent, products, title]);

	useEffect(() => {
		if (productsIds.length > 0) {
			api_getProductsByIds(productsIds.toString())
				.then(json => setProducts(json.items))
				.catch(error => console.error(error));
		} else if (categoryId !== undefined) {
			api_getPersonalizedProductsIdsByCategoryId(categoryId)
				.then(json => {
					setProducts(json.items);
					PersonalisedProducts.add(json.items);
				})
				.catch(error => console.error(error));
		}
	}, []);

	if (products.length === 0) return null;

	const setSliderPerView = () => {
		if (width >= 1265) return 4;
		if (width >= 768) return 3;
		if (width > 350) return 2;
		return 1;
	};

	return (
		<div id="personalized-products">
			<div className="content-wrapper">
				<h3 className="personalized-title">{title}</h3>
				<Swiper
					pagination={{
						dynamicBullets: true,
						clickable: true
					}}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false
					}}
					modules={[Pagination, Autoplay]}
					slidesPerView={setSliderPerView()}
					spaceBetween={10}
					className="mySwiper">
					{products.map((product, index) => (
						<SwiperSlide key={index}>
							<ProductCard product={product} showDimensions={false} />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

ProductsPersonalised.propTypes = {
	title: PropTypes.string,
	excludedProductId: PropTypes.number,
	categoryId: PropTypes.number
};

export default ProductsPersonalised;
