import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import SearchBar from './SearchBar';

function Search({
	searchTerm,
	setSearchTerm,
	searchResults,
	setSearchResults,
	setSearchTransition,
	showOnSearchScreen = false,
	loading,
	setLoading
}) {
	const { t } = useTranslation();
	let { eurl } = useParams();

	useEffect(() => {
		setSearchResults({ cats: [], prds: [] });
	}, [eurl]);

	return (
		<>
			<SearchBar
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				setSearchTransition={setSearchTransition}
				setSearchResults={setSearchResults}
				showOnSearchScreen={showOnSearchScreen}
				setLoading={setLoading}
			/>
			{searchTerm.length > 0 && searchResults.cats.length > 0 && (
				<div className="searchbar-window-predictions">
					{!loading &&
						searchResults.cats.map((category, index) => (
							<Link
								key={index}
								to={category.eurl}
								onClick={() => {
									setSearchTransition(false);
									setSearchTerm('');
								}}>
								<span>{category.nm}</span>
							</Link>
						))}
				</div>
			)}
		</>
	);
}

Search.propTypes = {
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	searchResults: PropTypes.object,
	setSearchTransition: PropTypes.func,
	setSearchResults: PropTypes.func,
	showOnSearchScreen: PropTypes.bool,
	loading: PropTypes.bool,
	setLoading: PropTypes.func
};

export default Search;
