import { convertImageUrl_L_to_M } from 'backend/helper';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { closeDesktopMenuAction, setActiveCategoryId } from '../../store/actions';

import closeicon from '../../images/close-button.svg';

const MenuDesktop = ({ hasSelectedMenuItem, menuItems, activeCategoryId, showTitle }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const menuWrapperRef = useRef(null);
	// useOutsideAlerter(menuWrapperRef, () => {
	// 	dispatch(closeDesktopMenuAction());
	// dispatch(setActiveCategoryId(null));
	// });

	const handleClickCategory = () => {
		dispatch(closeDesktopMenuAction());
		dispatch(setActiveCategoryId(null));
	};

	return (
		<div id="menu-desktop" ref={menuWrapperRef}>
			<hr />
			<div
				onClick={() => {
					dispatch(closeDesktopMenuAction());
					dispatch(setActiveCategoryId(null));
				}}
				className="menu-active-close-icon">
				<img src={closeicon} alt="close menu" />
			</div>
			<div className="menu-active-wrapper">
				<div className="menu-active">
					{hasSelectedMenuItem ? (
						<>
							<ul className="first-level-categories">
								{menuItems
									.filter(x => x.pid === activeCategoryId)
									.map((item, index) => (
										<li key={index} className="first-level-category">
											<h3 onClick={handleClickCategory} className="hover-underline-animation">
												<Link to={item.nu}>{item.tl}</Link>
											</h3>
											<ul>
												{menuItems
													.filter(x => x.pid === item.id)
													.map((childItem, childIndex) => (
														<li onClick={handleClickCategory} key={childIndex}>
															<Link to={childItem.nu}>{childItem.tl}</Link>
														</li>
													))}
											</ul>
										</li>
									))}
							</ul>
							{menuItems.find(x => x.id === activeCategoryId)?.img1 && (
								<div className="category-img">
									<img
										src={convertImageUrl_L_to_M(menuItems.find(x => x.id === activeCategoryId)?.img1)}
										alt="Category Name Image"
									/>
									<h4 onClick={handleClickCategory}>
										<Link to={menuItems.find(x => x.id === activeCategoryId)?.nu}>{t('see_all_products')}</Link>
									</h4>
								</div>
							)}
						</>
					) : (
						<>
							<ul className="first-level-categories-img">
								{menuItems
									.filter(x => x.pid === activeCategoryId)
									.map((item, index) => (
										<li key={index} className="first-level-category" onClick={handleClickCategory}>
											<Link to={item.nu}>
												<img src={convertImageUrl_L_to_M(item.img1)} alt={item.nm} />
												{showTitle && <span>{item.tl}</span>}
											</Link>
										</li>
									))}
							</ul>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

MenuDesktop.propTypes = {
	hasSelectedMenuItem: PropTypes.bool,
	menuItems: PropTypes.array,
	activeCategoryId: PropTypes.any,
	showTitle: PropTypes.bool
};

export default MenuDesktop;
