import { api_getProfile } from 'backend/api_calls.js';
import ShopCart from 'backend/shop_cart';
import UserState from 'backend/user_session';
import { userInfoGTAG4Event } from 'common/gtag4.js';
import SwitchAccount from 'components/checkout/CheckoutStep1/SwitchAccount';
import PageHeaderMeta from 'components/common/PageHeaderMeta';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Login from '../Customer/Login.js';

const CheckoutStep1 = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const isAuthenticated = UserState.isAuthenticated();
	const [forceShowLogin, setForceShowLogin] = useState(!UserState.isAuthenticated());

	useEffect(() => {
		if (ShopCart.getItems().length == 0) {
			navigate('/');
			return;
		}
	}, []);

	// Google Tag Manager Event
	useEffect(() => {
		if (UserState.isAuthenticated()) {
			api_getProfile().then(json => {
				userInfoGTAG4Event(json);
			});
		} else if (UserState.getEmail()) {
			userInfoGTAG4Event({ eml: UserState.getEmail() });
		}
	}, [isAuthenticated]);

	return (
		<>
			<PageHeaderMeta page_title={t('checkoutheader_step1_title')} />

			<div className="checkout-step1-page">
				{!forceShowLogin ? (
					<SwitchAccount setForceShowLogin={setForceShowLogin} />
				) : (
					<Login redirectOnLogin="/checkout-step-2" setForceShowLogin={setForceShowLogin} />
				)}
			</div>
		</>
	);
};

export default CheckoutStep1;
