import PropTypes from 'prop-types';
import React from 'react';

const Button = ({
	children,
	color = 'primary',
	outline = false,
	icon,
	style,
	actionOnClick,
	disabled = false,
	className = ''
}) => {
	const themeColors = {
		primary: {
			background: '#0154FF',
			color: '#fff',
			outlineColor: '#0154FF'
		},
		secondary: {
			background: '#000000',
			color: '#fff',
			outlineColor: '#000000'
		},
		light: {
			background: '#fff',
			color: '#000000',
			outlineColor: '#fff'
		}
	};

	const styles = {
		fontFamily: "'Roboto Condensed', sans-serif",
		backgroundColor: !outline && themeColors[color].background,
		color: outline ? themeColors[color].outlineColor : themeColors[color].color,
		fontSize: '16px',
		border: outline && `1px solid ${themeColors[color].background}`,
		outline: 'none',
		borderRadius: '0px',
		padding: '18px 50px',
		opacity: disabled ? '0.6' : '1',
		...style
	};

	return (
		<button style={styles} className={`${color} ${className}`} onClick={actionOnClick} disabled={disabled}>
			{children}
		</button>
	);
};

Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	color: PropTypes.oneOf(['primary', 'secondary', 'light']),
	outline: PropTypes.bool,
	icon: PropTypes.string,
	style: PropTypes.object,
	actionOnClick: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string
};

export default Button;
