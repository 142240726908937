const initialState = {
	showMobileMenu: false,
	showDesktopMenu: false,
	activeCategoryId: null,
	showAddToWishlist: false,
	wishlistProductId: 0,
	handledProduct: {
		productId: 0,
		active: true
	},
	showCouponPopup: false
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'openMobileMenu':
			state = { ...state, showMobileMenu: true };
			break;
		case 'closeMobileMenu':
			state = { ...state, showMobileMenu: false };
			break;
		case 'openDesktopMenu':
			state = { ...state, showDesktopMenu: true };
			break;
		case 'closeDesktopMenu':
			state = { ...state, showDesktopMenu: false };
			break;
		case 'setActiveCategoryId':
			state = { ...state, activeCategoryId: action.categoryId };
			break;
		case 'add-to-wishlist':
			state = { ...state, showAddToWishlist: true, wishlistProductId: action.productId };
			break;
		case 'close-add-to-wishlist':
			state = { ...state, showAddToWishlist: false, wishlistProductId: action.productId };
			break;
		case 'update-handled-product':
			state = { ...state, handledProduct: action.handledProduct };
			break;
		case 'open-coupon-popup':
			state = { ...state, showCouponPopup: true };
			break;
		case 'close-coupon-popup':
			state = { ...state, showCouponPopup: false };
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default authReducer;
