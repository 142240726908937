import ProductsPersonalised from 'components/common/ProductsPersonalised';
import React from 'react';
import { useTranslation } from 'react-i18next';

function NoResults() {
	const { t } = useTranslation();

	return (
		<div className="no-results">
			<h3>{t('search_keywords_results_none')} 😥</h3>
			<p>{t('search_again')}</p>
			<ProductsPersonalised title={t('might_like_title')} />
		</div>
	);
}

export default NoResults;
