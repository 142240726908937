import UserSession from 'backend/user_session';
import Button from 'components/common/Button';
import { notifyError } from 'components/common/ToastMessages';
import { validateGuestUserEmail } from 'core/checkout/utils/checkout_step_1';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

// THE GUEST FORM IS ONLY VISIBLE ON CHECKOUT ROUTE
function GuestForm() {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	const { t, i18n } = useTranslation();

	const handleFastCheckout = () => {
		if (!validateGuestUserEmail(email)) {
			notifyError(t('validation_email'));
			return;
		}
		UserSession.clearData();
		UserSession.setEmail(email);

		navigate('/checkout-step-2');
	};

	return (
		<div className="guest-option">
			<p>{t('guest_info_title')}</p>
			<input
				className="email-input"
				type="email"
				placeholder="Email"
				value={email}
				onChange={e => setEmail(e.target.value)}
			/>
			<Button outline={true} color="secondary" actionOnClick={handleFastCheckout}>
				{t('register_guest')}
			</Button>
		</div>
	);
}

GuestForm.propTypes = {
	redirectOnLogin: PropTypes.string
};

export default GuestForm;
