import { convertImageUrl_L_to_S } from 'backend/helper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { closeMobileMenuAction } from '../../store/actions.js';

import arrowrighticon from '../../images/arrow-right.svg';
import clearance from '../../images/clearance.svg';
import closeicon from '../../images/close-button.svg';

import { changeLanguage } from 'common/helper';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import englishicon from '../../images/lang-en.svg';
import greekicon from '../../images/lang-gr.svg';

const MenuMobile = ({ menuItems }) => {
	const dispatch = useDispatch();

	const { t, i18n } = useTranslation();
	const location = useLocation();
	const { pathname } = location;
	const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
	const navigate = useNavigate();

	const handleSelectCategory = category => {
		// If it is not menu item (SPORTS BRAND PAGE)
		if (category.eurl) {
			// navigate(hanldeConstructLink(category));
			dispatch(closeMobileMenuAction());
			return;
		}

		const hasChildren = menuItems.filter(x => x.pid == category?.id).length;

		if (!hasChildren) {
			navigate(category.nu);
			dispatch(closeMobileMenuAction());
		}

		setSelectedSubCategory(category);
	};

	const handleGoBack = () => {
		const parentCat = menuItems.find(x => x.id == selectedSubCategory.pid);

		if (parentCat == undefined) {
			setSelectedSubCategory(undefined);
			return;
		}

		setSelectedSubCategory(parentCat);
	};

	return (
		<div id="menu-modal">
			<div className="header">
				<div className="lang-change" onClick={() => changeLanguage(pathname, i18n)}>
					{localStorage.getItem('I18N_LANGUAGE') == 'el' ? (
						<img src={greekicon} alt="greek" />
					) : (
						<img src={englishicon} alt="english" />
					)}
				</div>
				<div onClick={() => dispatch(closeMobileMenuAction())} className="close-icon">
					<img src={closeicon} alt="close menu" />
				</div>
			</div>
			{/* <div className="search">
				<input type="text" placeholder={t('mobile_header_search_input_placeholder')}></input>
				<button>
					<img src={searchicon} alt="Search icon" />
				</button>
			</div> */}
			<nav>
				{selectedSubCategory && (
					<div className="back" onClick={() => handleGoBack()}>
						<h3>{t('back')}</h3>
					</div>
				)}
				<div className="categories">
					{menuItems
						.filter(
							x => x.pid == selectedSubCategory?.id && x.cd != 'BRANDS' && x.cd != 'SPORTS' && x.cd != 'CLEARANCE'
						)
						.map((item, index) => {
							if (item.cd == 'clearance') {
								return (
									<div key={index} className="category" onClick={() => handleSelectCategory(item)}>
										<div className={`category-info has-image`}>
											<img src={clearance} alt="Clearance" />
											<h3>{item.tl}</h3>
										</div>
										<div className="browse-icon">
											<img src={arrowrighticon} alt="Browse category icon" />
										</div>
									</div>
								);
							} else {
								return (
									<div key={index} className="category" onClick={() => handleSelectCategory(item)}>
										<div className={`category-info ${item.img2 && 'has-image'}`}>
											{item.img2 && <img src={convertImageUrl_L_to_S(item.img2)} alt={`${item.tl} Category Image`} />}
											<h3>{item.tl}</h3>
										</div>
										<div className="browse-icon">
											<img src={arrowrighticon} alt="Browse category icon " />
										</div>
									</div>
								);
							}
						})}
				</div>
				{menuItems
					.filter(x => x.cd == 'BRANDS' || x.cd == 'SPORTS' || x.cd == 'CLEARANCE')
					.map((item, index) => {
						if (item.cd == 'BRANDS') {
							return (
								<div key={index} className="brands">
									<div className="brands-header">
										<h3>{item.tl}</h3>
										<h4 onClick={() => dispatch(closeMobileMenuAction())}>
											<Link to={item.nu}>{t('see_all')}</Link>
										</h4>
									</div>
									<div className="brands-content">
										{menuItems
											.filter(x => x.pid === item.id)
											.map((chilldItem, childIndex) => (
												<img
													key={childIndex}
													src={chilldItem.img1}
													alt={item.nm}
													onClick={() => handleSelectCategory(chilldItem)}
												/>
											))}
									</div>
								</div>
							);
						} else if (item.cd == 'SPORTS') {
							return (
								<div key={index} className="sports">
									<div className="sports-header">
										<h3>{item.tl}</h3>
										<h4 onClick={() => dispatch(closeMobileMenuAction())}>
											<Link to={item.nu}>{t('see_all')}</Link>
										</h4>
									</div>
									<div className="sports-content">
										{menuItems
											.filter(x => x.pid === item.id)
											.map((chilldItem, childIndex) => (
												<div key={childIndex} className="sport" onClick={() => handleSelectCategory(chilldItem)}>
													<img src={chilldItem.img2 ? chilldItem.img2 : chilldItem.img1} alt={chilldItem.tl} />
													<span>{chilldItem.tl}</span>
												</div>
											))}
									</div>
								</div>
							);
						} else if (item.cd == 'CLEARANCE') {
							return (
								<div key={index} className="categories">
									<div className="category" onClick={() => handleSelectCategory(item)}>
										<div className={`category-info has-image`}>
											<img src={clearance} alt="Clearance" />
											<h3>{item.tl}</h3>
										</div>
										<div className="browse-icon">
											<img src={arrowrighticon} alt="Browse category icon" />
										</div>
									</div>
								</div>
							);
						}
					})}
			</nav>
		</div>
	);
};

MenuMobile.propTypes = {
	menuItems: PropTypes.array
};

export default MenuMobile;
