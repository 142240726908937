import CheckoutSessionStorage from '../utils/CheckoutSessionStorage';

const initialState = {
	error: '',
	orderResponse: {},
	coupons: CheckoutSessionStorage.getCoupons(),
	giftcards: CheckoutSessionStorage.getGiftcards(),
	shipToBillingAddress: CheckoutSessionStorage.getOrder()?.shipToBillingAddress == true ? true : false,
	receiptType: CheckoutSessionStorage.getOrder()?.receiptType || 0,
	selectedAddressId: CheckoutSessionStorage.getOrder()?.selectedAddressId || 0,
	selectedAddressCode: CheckoutSessionStorage.getOrder()?.selectedAddressCode || '',
	selectedNewAddress: CheckoutSessionStorage.getOrder()?.selectedNewAddress || false,
	inputErrors: {},
	orderTotals: {},
	pickupFromStore: false,
	takeAwayStoreId: 0,
	shipping: {},
	payment: {},
	order: CheckoutSessionStorage.getOrder(),
	isGift: false,
	selectedLoyaltyPoints: CheckoutSessionStorage.getLoyaltyPoints(),
	newsletterAccept: true,
	privacyPolicyAccept: true,
	loading: false
};

const checkoutReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'checkout-error':
			state = { ...state, error: action.error, loading: false };
			break;
		case 'set-coupons':
			state = { ...state, coupons: action.coupons, loading: false };
			break;
		case 'set-giftcards':
			state = { ...state, giftcards: action.giftcards, loading: false };
			break;
		case 'change-loyalty-points':
			state = { ...state, selectedLoyaltyPoints: action.selectedLoyaltyPoints, loading: false };
			break;
		case 'ship-to-billing-changed':
			state = { ...state, shipToBillingAddress: action.shipToBillingAddress, loading: false };
			break;
		case 'receipt-type-selected':
			state = { ...state, receiptType: action.receiptType, loading: false };
			break;
		case 'set-selected-address-id':
			state = { ...state, selectedAddressId: action.selectedAddressId, loading: false };
			break;
		case 'set-selected-address-code':
			state = { ...state, selectedAddressCode: action.selectedAddressCode, loading: false };
			break;
		case 'has-selected-new-address':
			state = { ...state, selectedNewAddress: action.selectedNewAddress, loading: false };
			break;
		case 'set-inputs-errors':
			state = { ...state, inputErrors: action.inputs, loading: false };
			break;
		case 'set-pickup-from-store':
			state = { ...state, pickupFromStore: action.pickup, loading: false };
			break;
		case 'set-take-away-store-id':
			state = { ...state, takeAwayStoreId: action.storeId, loading: false };
			break;
		case 'shipping-selected':
			state = { ...state, shipping: action.shipping, loading: false };
			break;
		case 'payment-selected':
			state = { ...state, payment: action.payment, loading: false };
			break;
		case 'set-order-totals':
			state = { ...state, orderTotals: action.total, loading: false };
			break;
		case 'order-changed':
			state = { ...state, order: action.order, loading: false };
			break;
		case 'newsletter-toggled':
			state = { ...state, newsletterAccept: action.newsletterAccept, loading: false };
			break;
		case 'privacy-policy-toggled':
			state = { ...state, privacyPolicyAccept: action.privacyPolicyAccept, loading: false };
			break;
		case 'gift-toggled':
			state = { ...state, isGift: action.isGift, loading: false };
			break;
		case 'order-completed':
			state = { ...state, order: action.order, loading: true };
			break;
		case 'order-complete-success':
			state = { ...state, orderResponse: action.response, loading: false };
			break;
		case 'clear-messages':
			state = { ...state, error: '', cartMsg: '', orderResponse: {} };
			break;
		case 'reset-checkout-store':
			state = initialState;
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default checkoutReducer;
