import { calculateProductPriceValue } from './helper.js';

const handleConstructProductObject = (product, listName = null) => {
	const productObject = {};

	productObject.item_id = product.cd;
	productObject.item_name = product.nm;

	if (product.prcbd) {
		productObject.discount = product.prcbd - product.prc;
	} else if (product.dsc) {
		productObject.discount = (product.prc * product.dsc) / 100;
	}

	if (product.index >= 0) {
		productObject.index = product.index;
	}

	productObject.item_brand = product.atrlst?.find(item => item.anm === 'Brand' || item.anm === 'BRAND')?.tval ?? '';

	product.breadcrumbs.map((item, index) => {
		let key = 'item_category';
		if (index === 0) {
			key = `item_category`;
		} else {
			key = `item_category${index + 1}`;
		}
		productObject[key] = item.nm;
	});

	productObject.price = calculateProductPriceValue(product);

	if (listName) productObject.item_list_name = listName;

	// For cart view
	if (product.quantity) productObject.quantity = product.quantity;

	return productObject;
};

// USER INFO
export const userInfoGTAG4Event = profile => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		user_data: {
			userId: profile.id,
			email: profile.eml,
			phone_number: profile.address?.mob ?? '',
			address: {
				first_name: profile.address?.fn ?? '',
				last_name: profile.address?.ln ?? '',
				street: profile.address?.adl ?? '',
				city: profile.address?.ct ?? '',
				region: '',
				postal_code: profile.address?.pc ?? ''
			}
		}
	});
};

// VIEW ITEM LIST
export const viewItemListGTAG4Event = (products, listName) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'view_item_list',
		ecommerce: {
			items: products.map(product => handleConstructProductObject(product, listName))
		}
	});
};

// SELECT ITEM - FREEZED
export const selectItemGTAG4Event = (product, listName) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'select_item',
		ecommerce: {
			item_list_name: listName,
			items: [handleConstructProductObject(product)]
		}
	});
};

// VIEW ITEM
export const viewItemGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'view_item',
		ecommerce: {
			currency: 'EUR',
			value: calculateProductPriceValue(product),
			items: [handleConstructProductObject(product)]
		}
	});
};

// ADD TO CART
export const addToCartGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_to_cart',
		ecommerce: {
			currency: 'EUR',
			value: calculateProductPriceValue(product),
			items: [handleConstructProductObject(product)]
		}
	});
};

// REMOVE FROM CART
export const removeFromCartGTAG4Event = product => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'remove_from_cart',
		ecommerce: {
			currency: 'EUR',
			value: calculateProductPriceValue(product),
			items: [handleConstructProductObject(product)]
		}
	});
};

// VIEW CART
export const viewCartGTAG4Event = (products, cartTotals) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'view_cart',
		ecommerce: {
			currency: 'EUR',
			value: cartTotals,
			items: products.map(product => handleConstructProductObject(product))
		}
	});
};

// BEGIN CHECKOUT
export const beginCheckoutGTAG4Event = (products, orderTotals) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'begin_checkout',
		ecommerce: {
			currency: 'EUR',
			value: orderTotals,
			items: products.map(product => handleConstructProductObject(product))
		}
	});
};

// ADD SHIPPING INFORMATION
export const addShippingInfoGTAG4Event = (products, orderTotals, courierLabel) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_shipping_info',
		ecommerce: {
			currency: 'EUR',
			value: orderTotals,
			shipping_tier: courierLabel,
			items: products.map(product => handleConstructProductObject(product))
		}
	});
};

// ADD PAYMENT INFORMATION
export const addPaymentInfoGTAG4Event = (products, orderTotals, paymentLabel) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'add_payment_info',
		ecommerce: {
			currency: 'EUR',
			value: orderTotals,
			payment_type: paymentLabel,
			items: products.map(product => handleConstructProductObject(product))
		}
	});
};

// PURCHASE
export const purchaseGTAG4Event = (products, orderUid, orderTotals, taxTotal, shippingTotals, coupons) => {
	window.dataLayer.push({ ecommerce: null });

	window.dataLayer.push({
		event: 'purchase',
		ecommerce: {
			transaction_id: orderUid,
			value: orderTotals,
			tax: taxTotal,
			shipping: shippingTotals,
			currency: 'EUR',
			coupons: coupons,
			items: products.map(product => handleConstructProductObject(product))
		}
	});
};
