import Button from 'components/common/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setShowNotification } from 'store/actions';

const ShopForFriends = ({ setOpen }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	return (
		<>
			<div className="header">
				<div className="title">
					{t('application_from_now_on')} <br />
					<span>{t('application_your_friends')}</span>
					<span> {t('application_with_discount')} 45%</span>
				</div>
			</div>
			<div className="body"></div>
			<div className="footer">
				<Button color="secondary" actionOnClick={() => dispatch(setShowNotification(false))}>
					{t('close')}
				</Button>
			</div>
		</>
	);
};

ShopForFriends.propTypes = {
	setOpen: PropTypes.func
};

export default ShopForFriends;
