import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { api_setCurrentCustomerMeta } from 'backend/api_calls';
import BreadcrumbsSimple from 'components/common/BreadcrumbSimple';
import Button from 'components/common/Button';
import Checkbox from 'components/common/Checkbox';
import PageHeaderMeta from 'components/common/PageHeaderMeta';
import { notifyError } from 'components/common/ToastMessages';
import useApplication from 'components/layout/application/useApplication';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import { setHasAcceptedApplicationTerms } from 'store/actions';
import footer_image from '../../images/app-footer-img.jpg';
import gem_image from '../../images/app-gem.jpg';
import hero_image from '../../images/app-hero-image.jpg';
import phone_image from '../../images/app-phone-bg.jpg';
import nextStep from '../../images/parallax-full-image.png';
import initialStep from '../../images/sportistas-giftcard1.png';

const Application = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { checkIsApplicationUser, checkHasAcceptedTerms } = useApplication();
	const [accepted, setAccepted] = useState(false);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

	useEffect(() => {
		const handleRedirect = async () => {
			const isApplication = await checkIsApplicationUser();
			const hasAcceptedTermsRes = await checkHasAcceptedTerms();

			if (!isApplication) {
				navigate('/not-found');
				return;
			}

			setHasAcceptedTerms(hasAcceptedTermsRes);
		};
		handleRedirect();
	}, []);

	const submit = () => {
		if (hasAcceptedTerms) {
			navigate('/profile');
			return;
		}

		if (!accepted) {
			notifyError(t('validation_terms'));
			return;
		}

		api_setCurrentCustomerMeta('HasAcceptedApplicationTerms', { type: 3, boolean: true })
			.then(res => {
				dispatch(setHasAcceptedApplicationTerms(true));
				navigate('/profile');
			})
			.catch(err => {
				notifyError('Προέκυψε κάποιο πρόβλημα');
				console.log(err);
			});
	};

	const screenWidth = window.innerWidth;
	const viewOptions = view => {
		if (view === 'view0') {
			switch (true) {
				default:
					return { threshold: 0, rootMargin: '0px' };
			}
		}
		if (view === 'view1') {
			switch (true) {
				case screenWidth < 1480:
					return { threshold: 0, rootMargin: '180px' };
				default:
					return { threshold: 0, rootMargin: '0px' };
			}
		}
		if (view === 'view2') {
			switch (true) {
				case screenWidth < 520:
					return { threshold: 0, rootMargin: '80px' };
				default:
					return { threshold: 0, rootMargin: '-260px' };
			}
		}
		if (view === 'view3') {
			switch (true) {
				case screenWidth < 400:
					return { threshold: 0, rootMargin: '0px' };
				case screenWidth < 520:
					return { threshold: 0, rootMargin: '-200px' };
				default:
					return { threshold: 0, rootMargin: '-250px' };
			}
		}
		if (view === 'view4') {
			switch (true) {
				default:
					return { threshold: 0, rootMargin: '-1300px' };
			}
		}
	};

	const { ref: ref0, inView: view0 } = useInView(viewOptions('view0'));
	const { ref: ref1, inView: view1 } = useInView(viewOptions('view1'));
	const { ref: ref2, inView: view2 } = useInView(viewOptions('view2'));
	const { ref: ref3, inView: view3 } = useInView(viewOptions('view3'));
	const { ref: ref4, inView: view4 } = useInView(viewOptions('view4'));

	const [isHidden, setIsHidden] = useState(false);

	useEffect(() => {
		if ((!view0 && !view1 && !view2 && !view3 && !view4) || view4) {
			setIsHidden(true);
		} else {
			setIsHidden(false);
		}
	}, [view0, view1, view2, view3, view4]);

	return (
		<div id="application-landing">
			<PageHeaderMeta page_title={'Mobile Application'} />
			<BreadcrumbsSimple currentName={t('Mobile Application Users')} />

			<div className="content-wrapper">
				<section className="upper-section" id="0">
					<h1>
						<p className="title">WELCOME TO THE</p>
						<p className="title">SPORTISTAS CLUB</p>
					</h1>
					<div className="texts">
						<p>Πλέον δεν χρειάζεσαι τη mobile εφαρμογή για να απολαύσεις τα μοναδικά σου προνόμια!</p>
						<p>
							Το ΝΕΟ SPORTISTAS.COM σχεδιάστηκε για εσένα! Για να συνεχίσεις να απολαμβάνεις και να μοιράζεσαι{' '}
							<b>προνομιακές & αποκλειστικές εκπτώσεις </b> σε κορυφαία brands όπως O’neil, Teva, Speedo και φυσικά σε
							όλα τα προϊόντα της ASICS!
						</p>
						<p>
							Ακολούθησε τις οδηγίες και ξεκλείδωσε <b>έκπτωση -60% για εσένα και -45% για τους φίλους σου.</b>
						</p>
					</div>
					<img src={hero_image} alt="Four people running." ref={ref0} />
				</section>
				<div className="dual-monitor">
					<div className="monitor1">
						<section className="steps-section">
							<h2>ΠΡΙΝ ΞΕΚΙΝΗΣΕΙΣ ΔΙΑΒΑΣΕ ΤΑ ΒΗΜΑΤΑ</h2>
							<div className="steps">
								<div className="step step1">
									<div className="text">
										<h3>
											1<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Αφού πραγματοποιήσεις <b>“Σύνδεση”</b>, μπες στο προφίλ σου και επίλεξε το πεδίο “ SPORTISTAS
												APPLICATION”
											</p>
										</div>
									</div>
									<div className="content" id="1" ref={ref1}></div>
								</div>
								<div className="step step2">
									<div className="text">
										<h3>
											2<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Θα χρειαστεί να επιλέξεις <b>το Φύλο σου</b> και στη συνέχεια το{' '}
												<b> Μέγεθός σου σε Ρούχα και Παπούτσια.</b>
											</p>
										</div>
									</div>
									<div className="content" id="2" ref={ref2}></div>
								</div>
								<div className="step step3">
									<div className="text">
										<h3>
											3<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Αποθήκευσε τις επιλογές σου, πατώντας <b>&quot;ΑΠΟΘΗΚΕΥΣΗ&quot;</b>
											</p>
										</div>
									</div>
									<div className="content" id="3" ref={ref3}></div>
								</div>
								<div className="step step4" ref={ref4}>
									<div className="text">
										<h3>
											4<sup>o</sup> ΒΗΜΑ
										</h3>
										<div>
											<p>
												Αφού πατήσεις αποθήκευση, θα μπορείς να απολαμβάνεις
												<b> έκπτωση -60% σε αγορές για το δικό σου Φύλο και Μέγεθος. </b> Αλλά μην αγχώνεσαι..
											</p>
											<p>
												Έχεις και έκπτωση <b> -45% σε αγορές προϊόντων οποιουδήποτε Φύλου και Μεγέθους </b> για να
												μοιράζεσαι την εμπειρία Sportistas <b> με τους φίλους σου! </b> Είσαι έτοιμος;
											</p>
										</div>
									</div>
								</div>
							</div>
						</section>
						<section className="footer-section">
							<div className="inner-part">
								{!hasAcceptedTerms && (
									<Checkbox
										label="Εχω καταλάβει τα βήματα."
										checked={accepted}
										onChange={() => setAccepted(!accepted)}
									/>
								)}
								<Button color="primary" actionOnClick={submit}>
									ΞΕΚΙΝΑ ΕΔΩ
								</Button>

								<img src={gem_image} alt="Decoration" />
							</div>
						</section>
					</div>
					<div className="monitor2">
						{!isHidden && (
							<div className="parallax-phone">
								<div className="parallax-container">
									<img className="phone" src={phone_image} alt="Fake Phone" />
									{(!view0 || view1) && (
										<div className={`image-holder inner-img ${view3 ? 'next-step' : ''}`}>
											<img src={nextStep} alt="Steps" />
										</div>
									)}
									{view0 && !view1 && screenWidth < 768 && (
										<div className="image-holder initial-step">
											<img src={initialStep} alt="Initial Step" />
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				<img className="footer-img" src={footer_image} alt="Tennis Player" />
			</div>
		</div>
	);
};

export default Application;
