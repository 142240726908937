// ShopCart object defined as a closure wrapper
var ShopCart = (function () {
	var isEmpty = function () {
		var items = getItems();
		return items.length == 0;
	};

	var getItems = function () {
		try {
			var items = JSON.parse(localStorage.getItem('shopcart_items'));

			if (items == null) {
				items = [];
			}

			return items;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getItemsForTagManager = function () {
		try {
			var items = JSON.parse(localStorage.getItem('shopcart_items'));

			if (items == null) {
				items = [];
			}

			const products = items.map(item => {
				return { ...item.product, quantity: item.quantity };
			});

			return products;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getCoupons = function () {
		try {
			var coupons = JSON.parse(sessionStorage.getItem('shopcart_coupons'));

			if (coupons == null) {
				coupons = [];
			} else if (!Array.isArray(coupons) || !coupons.every(element => typeof element === 'string')) {
				coupons = [];
			}

			setCoupons(coupons);
			return coupons;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setCoupons = function (coupons) {
		try {
			if (coupons == null) {
				coupons = [];
			} else if (!Array.isArray(coupons) || !coupons.every(element => typeof element === 'string')) {
				coupons = [];
			}

			sessionStorage.setItem('shopcart_coupons', JSON.stringify(coupons));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getGiftcards = function () {
		try {
			var giftcards = JSON.parse(sessionStorage.getItem('shopcart_giftcards'));

			if (giftcards == null) {
				giftcards = [];
			} else if (!Array.isArray(giftcards) || !giftcards.every(element => typeof element === 'string')) {
				giftcards = [];
			}

			setGiftcards(giftcards);
			return giftcards;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setGiftcards = function (giftcards) {
		try {
			if (giftcards == null) {
				giftcards = [];
			} else if (!Array.isArray(giftcards) || !giftcards.every(element => typeof element === 'string')) {
				giftcards = [];
			}

			sessionStorage.setItem('shopcart_giftcards', JSON.stringify(giftcards));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var getLoyaltyPoints = function () {
		try {
			var points = JSON.parse(localStorage.getItem('shopcart_loyaltypoints'));

			if (points == null) {
				points = 0;
			} else if (typeof points !== 'number' || !Number.isInteger(points)) {
				points = 0;
			}

			setLoyaltyPoints(points);
			return points;
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var setLoyaltyPoints = function (point) {
		try {
			if (point == null) {
				point = 0;
			}
			localStorage.setItem('shopcart_loyaltypoints', JSON.stringify(point));
		} catch (e) {
			console.log(e);
			return [];
		}
	};

	var clear = function () {
		try {
			localStorage.removeItem('shopcart_items');
		} catch (e) {
			console.log(e);
		}
	};

	var find = function (pid) {
		var items = getItems();

		for (let item of items) {
			if (item.pid == pid) {
				return item;
			}
		}

		return null;
	};

	var changeQuantity = function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.pid == token.pid && item.dimension == token.dimension) {
					item.quantity = token.quantity;
					found = true;
					break;
				}
			}

			if (found) {
				localStorage.setItem('shopcart_items', JSON.stringify(items));
			}

			return JSON.parse(localStorage.getItem('shopcart_items'));
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	var add = async function (token) {
		try {
			var items = getItems();
			var found = false;

			for (let item of items) {
				if (item.pid == token.pid && item.dimension == token.dimension) {
					item.quantity += token.quantity;
					found = true;
					break;
				}
			}

			if (!found) {
				items.push(token);
			}

			localStorage.setItem('shopcart_items', JSON.stringify(items));

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var remove = function (token) {
		try {
			var items = getItems();
			let filtered = items.filter(item => item.pid !== token.pid || item.dimension !== token.dimension);

			if (filtered.length != items.length) {
				localStorage.setItem('shopcart_items', JSON.stringify(filtered));
				return JSON.parse(localStorage.getItem('shopcart_items'));
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	};

	var setDBCart = function (items) {
		try {
			localStorage.setItem('shopcart_items', JSON.stringify(items));

			return true;
		} catch (e) {
			console.error(e);
			return false;
		}
	};

	var getTotalQuantity = function () {
		try {
			var items = JSON.parse(localStorage.getItem('shopcart_items'));
			var totalQuantity = 0;

			if (items != null) {
				items.forEach(item => (totalQuantity += item.quantity));
			}

			return totalQuantity;
		} catch (e) {
			console.log(e);
			return totalQuantity;
		}
	};

	return {
		isEmpty,
		getItems,
		getItemsForTagManager,
		getCoupons,
		setCoupons,
		getGiftcards,
		setGiftcards,
		getLoyaltyPoints,
		setLoyaltyPoints,
		clear,
		find,
		add,
		remove,
		changeQuantity,
		getTotalQuantity,
		setDBCart
	};
})();

export default ShopCart;
