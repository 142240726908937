import PropTypes from 'prop-types';
import React from 'react';

import close from '../../images/close-btn.svg';

const Modal = ({ children, open, setOpen, closeOnClickOutside = true, className = '' }) => {
	if (!open) return null;

	const handleClickOutside = () => {
		if (!closeOnClickOutside) return;

		setOpen(false);
	};

	return (
		<div
			style={{ visibility: open ? 'unset' : 'hidden' }}
			className="modal-dialog-overlay"
			onClick={handleClickOutside}>
			<div className={'modal-dialog ' + className} onClick={e => e.stopPropagation()}>
				<div className="close-modal-btn" onClick={() => setOpen(false)}>
					<img src={close} alt="close-modal" />
				</div>

				{children}
			</div>
		</div>
	);
};

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	open: PropTypes.bool,
	setOpen: PropTypes.func,
	closeOnClickOutside: PropTypes.bool,
	className: PropTypes.string
};

export default Modal;
