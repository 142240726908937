import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const BreadcrumbsSimple = ({ parentLink, parentName, currentName }) => {
	if (currentName === undefined) return null;

	return (
		<div className="breadcrumbs-container bread-simple">
			<div id="breadcrumbs">
				<div className="bread-item">
					<Link to="/">Home</Link>
				</div>

				{parentLink && parentName && (
					<div className="bread-item">
						<Link to={parentLink}>{parentName}</Link>
					</div>
				)}

				<div className="bread-item">{currentName}</div>
			</div>
		</div>
	);
};

BreadcrumbsSimple.propTypes = {
	parentLink: PropTypes.string,
	parentName: PropTypes.string,
	currentName: PropTypes.string
};

export default BreadcrumbsSimple;
