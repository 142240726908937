import { api_getProductLoyaltyPoints } from 'backend/api_calls';
import { calculateProductPriceBDValue, calculateProductPriceValue, formatAmountValue } from 'common/helper';
import { notifyError, notifyInfo } from 'components/common/ToastMessages';
import { notifyInfoWishlist } from 'components/common/WishlistMessage';
import useWindowSize from 'components/hooks/useWindowSize';
import useApplication from 'components/layout/application/useApplication';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { addToCartAction, openCartWidgetAction } from 'store/cart/actions';
import { addToWishlistAction } from 'store/layout/actions';
import GuestWishlist from '../../../../backend/guest_wishlist';
import UserSession from '../../../../backend/user_session';
import noimage_png from '../../../../images/no-image.png';

const useProductCard = (product, calculateDefaultDimensions = false) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const { preselectSize } = useApplication();
	const [code, setCode] = useState();
	const [hasAnyStock, setHasAnyStock] = useState(true);
	const [sizes, setSizes] = useState([]);
	const [chosenColor, setChosenColor] = useState(0);
	const [chosenSize, setChosenSize] = useState({});
	const [uniqueColors, setUniqueColors] = useState([]);
	const [statuses, setStatuses] = useState([]);
	const [wishlistActive, setWishlistActive] = useState(false);
	const [image, setImage] = useState(noimage_png);
	const [loyaltyPoints, setLoyaltyPoints] = useState(0);
	const [price, setPrice] = useState(0);
	const [priceBD, setPriceBD] = useState(0);
	const [discount, setDiscount] = useState(0);
	const handledWishlistProduct = useSelector(state => state.layoutReducer.handledProduct);

	useEffect(() => {
		if (!product.id) return;

		// Setting Product Code
		const productCode = product.mfnm === 'SPEEDO' ? product.altcd : product.dimensions[0]?.altbc;
		setCode(productCode);

		const uniqueColors = Object.values(
			product.dimensions.reduce((acc, item) => {
				if (item.cval) {
					if (!acc[item.cval] || (acc[item.cval] && !acc[item.cval].img1)) {
						acc[item.cval] = item;
					}
				}
				return acc;
			}, {})
		);

		setUniqueColors(uniqueColors);

		if (uniqueColors.length > 0) {
			const firstColor = uniqueColors[0];
			const dimensionsIds = product.dimensions.filter(x => x.cval == firstColor.cval).map(y => y.id);
			const sizes = product.dimensions.filter(x => x.cval == null && dimensionsIds.includes(x.id));
			sizes.sort((a, b) => a.si - b.si);
			setSizes(sizes);

			// default selected color and size
			if (calculateDefaultDimensions) {
				setChosenColor(firstColor?.cval ?? 0);
				// check if url has selected size param
				const sizeParams = new URLSearchParams(window.location.search);
				let sizeQueryParam = sizeParams.get('size') ?? '';
				let sizeId = sizeQueryParam.split('-').pop();
				const selectedSize = sizes.find(size => size.id == sizeId);

				if (selectedSize?.id) {
					handleSizeChange(selectedSize);
				} else {
					const size = preselectSize(sizes, product.catlst);
					handleSizeChange(size);
				}
			}
		} else {
			const sizes = product.dimensions.filter(x => x.cval == null);
			sizes.sort((a, b) => a.si - b.si);
			setSizes(sizes);
		}

		// Handle set if has any stock available
		const hasDimensions = product.dimensions.length > 0;

		if (!hasDimensions) {
			if (product.qty <= 0) {
				setHasAnyStock(false);
			} else {
				setHasAnyStock(true);
			}
		} else {
			const dimensionsWithStock = product.dimensions.filter(dimension => dimension.qty > 0).length;
			if (dimensionsWithStock > 0) {
				setHasAnyStock(true);
			} else {
				setHasAnyStock(false);
			}
		}

		// Handle set Product's Tags/Statuses
		let statuses = product.stslst?.filter(x => x.iv === true);
		statuses?.sort((a, b) => a.si - b.si);
		statuses = statuses?.map(x => x.snm.normalize('NFD').replace(/[\u0300-\u036f]/g, '')); // Remove accents/diacritics so that uppercase works well

		setStatuses(statuses);

		// Handle Initial Wishlist Value
		setWishlistActive(product.inwishlist);
		if (!UserSession.isAuthenticated()) {
			setWishlistActive(GuestWishlist.find(product.id));
		}

		// Initialize Product's Price
		handleCalculateProductPrice();

		// Handle Set Product's Image
		setImage(product.img1 ?? noimage_png);
	}, [product]);

	useEffect(() => {
		if (!product.id || !chosenSize.id) return;

		handleCalculateProductPrice();
	}, [chosenSize]);

	useEffect(() => {
		if (handledWishlistProduct.productId == product.id) {
			setWishlistActive(handledWishlistProduct.active);
		}
	}, [handledWishlistProduct]);

	const handleWishlistToggle = () => {
		if (UserSession.isAuthenticated()) {
			dispatch(
				addToWishlistAction({
					productId: product.id
				})
			);
		} else {
			let status = GuestWishlist.addOrRemove(product);
			let message = '';

			if (status === 'added') {
				message = t('product_added_to_wishlist_guest');
				setWishlistActive(true);
			} else {
				message = t('product_removed_from_wishlist_guest');
				setWishlistActive(false);
			}

			notifyInfoWishlist(message);
		}
	};

	const handleColorChange = color => {
		const dimensionsIds = product.dimensions.filter(x => x.cval == color).map(y => y.id);
		const sizes = product.dimensions.filter(x => x.cval == null && dimensionsIds.includes(x.id));

		setSizes(sizes);
		setChosenColor(color);
		setChosenSize({});
	};

	const handleSizeChange = size => {
		if (!size.id) return;

		const sizeParams = new URLSearchParams();
		sizeParams.set('size', `${size.svtxt}-${size.id}`);
		const search = sizeParams.toString();
		const url = `${window.location.pathname}?${search}`;
		navigate(url, { replace: true });

		setChosenSize(size);
	};

	const handleCalculateProductPrice = () => {
		let dimensionId = 0;

		if (chosenSize) {
			dimensionId = product.dimensions.find(x => x.cval === chosenColor && x.bcd === chosenSize.bcd)?.id;
		}

		let price = calculateProductPriceValue(product, dimensionId);
		let priceBD = calculateProductPriceBDValue(product, dimensionId);
		let discount = handleCalculateProductDiscount();

		if (calculateDefaultDimensions) {
			handleCalculateProductLoyaltyPoints(price, priceBD, discount);
		}

		setPrice(formatAmountValue(price));
		setPriceBD(formatAmountValue(priceBD));
		setDiscount(discount);
	};

	const handleCalculateProductDiscount = () => {
		let dimension = {};

		if (chosenSize) {
			dimension = product.dimensions.find(x => x.cval === chosenColor && x.bcd === chosenSize.bcd);
		}

		let discountCalculation = 0;

		if (dimension?.id && dimension?.prc) {
			if (dimension.prcbd >= 0) {
				discountCalculation = ((dimension.prcbd - dimension.prc) / dimension.prcbd) * 100;
			} else if (dimension.dsc > 0 && dimension.dsc < 100) {
				discountCalculation = dimension.dsc;
			}
		} else {
			if (product.prcbd >= 0) {
				discountCalculation = ((product.prcbd - product.prc) / product.prcbd) * 100;
			} else if (product.dsc > 0) {
				discountCalculation = product.dsc;
			}
		}

		return discountCalculation;
	};

	const handleCalculateProductLoyaltyPoints = (price, priceBD, discount) => {
		api_getProductLoyaltyPoints(product.id, price, priceBD, discount).then(points => {
			setLoyaltyPoints(points);
		});
	};

	const handleAddToCart = (size = {}) => {
		// if (uniqueColors.length > 0 && !chosenColor) {
		// 	notifyError(t('select_color'));
		// 	return;
		// }

		if (sizes.length > 0 && !size.id) {
			notifyError(t('select_size'));
			return false;
		}

		const price = calculateProductPriceValue(product, size.id);

		if (!price || price <= 0) {
			notifyError(t('zero_price'));
			return false;
		}

		// const dimensions = await api_getProductDimensions(product.id);

		// for (let i = 0; i < dimensions.items.length; i++) {
		// 	let item = dimensions.items[i];
		// 	if (item.id == size.id) {
		// 		if (!item.chex && !item.cvtxt) {
		// 			let maxQty = item.qty !== undefined ? item.qty : product.qty;

		// 			console.log(process.env.REACT_APP_MAX_QUANTITY_PER_PRODUCT);
		// 			// Set max 5 if has more than five stock availble
		// 			if (maxQty > process.env.REACT_APP_MAX_QUANTITY_PER_PRODUCT) {
		// 				maxQty = process.env.REACT_APP_MAX_QUANTITY_PER_PRODUCT;
		// 			}

		// 			if (maxQty <= 0) {
		// 				notifyError(t('cart_item_error_sold_out'));
		// 				return;
		// 			}

		// 			if (ShopCart.find(product.id)?.quantity >= maxQty) {
		// 				notifyError(t('cart_item_error_max_quantity') + ShopCart.find(product.id).quantity);
		// 				return;
		// 			}
		// 		}
		// 	}
		// }

		dispatch(
			addToCartAction({
				pid: product.id,
				dimension: product.dimensions.length == 0 ? 0 : size.id,
				quantity: 1,
				product: product
			})
		);

		if (width > 900) {
			if (window.cartTimeout) {
				clearTimeout(window.cartTimeout);
			}

			dispatch(openCartWidgetAction(true));
			document.body.classList.add('cart-open');

			window.cartTimeout = setTimeout(() => {
				document.body.classList.remove('cart-open');
				dispatch(openCartWidgetAction(false));
			}, 2000);
		}

		notifyInfo(t('added_to_cart'), null, 1500);

		return true;
	};

	return {
		code,
		hasAnyStock,
		sizes,
		uniqueColors,
		chosenColor,
		chosenSize,
		statuses,
		image,
		loyaltyPoints,
		price,
		priceBD,
		discount,
		wishlistActive,
		handleColorChange,
		handleSizeChange,
		setChosenSize,
		handleWishlistToggle,
		handleAddToCart
	};
};

useProductCard.propTypes = {
	product: PropTypes.object
};
export default useProductCard;
