import useWindowSize from 'components/hooks/useWindowSize.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	closeDesktopMenuAction,
	openCartWidgetAction,
	openDesktopMenuAction,
	openMobileMenuAction,
	setActiveCategoryId
} from '../../../store/actions.js';

import { api_getMainMenu } from 'backend/api_calls.js';

import cartIcon from '../../../images/cart-icon.svg';
import wishlistIcon from '../../../images/heart-outline-black.svg';
import menu from '../../../images/menu.svg';
import userIcon from '../../../images/user-icon.svg';

import UserSession from 'backend/user_session';
import InfoMessage from './InfoMessage';

import ChangeLanguage from 'components/common/ChangeLanguage.js';
import MenuDesktop from 'components/common/MenuDesktop.js';
import MenuMobile from 'components/common/MenuMobile.js';
import SearchScreen from 'components/search/SearchScreen';
import CartWidget from '../../../components/common/CartWidget.js';
import CheckoutStep1 from '../../../pages/Checkout/CheckoutStep1.js';
import Login from '../../../pages/Customer/Login.js';
import ApplicationMode from '../application/ApplicationMode.js';
import Search from './Search.js';

const Header = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { width } = useWindowSize();
	const navigate = useNavigate();
	let { eurl } = useParams();

	const headerCartQuantity = useSelector(state => state.cartReducer.headerCartQuantity);
	const showLogin = useSelector(state => state.authReducer.showLoginModal);
	const showChechoutLoginModal = useSelector(state => state.authReducer.showCheckoutLoginModal);
	const openCartWidget = useSelector(state => state.cartReducer.showCartWidget);
	const showMobileMenu = useSelector(state => state.layoutReducer.showMobileMenu);
	const showDesktopMenu = useSelector(state => state.layoutReducer.showDesktopMenu);
	const activeCategoryId = useSelector(state => state.layoutReducer.activeCategoryId);

	const [searchTransition, setSearchTransition] = useState(false);

	const [menuItems, setMenuItems] = useState([]);

	const [hasSelectedMenuItem, setHasSelectedMenuItem] = useState(true);
	const [showTitle, setshowTitle] = useState(false);

	const [searchTerm, setSearchTerm] = useState('');
	const [searchResultsLoading, setSearchResultsLoading] = useState(false);
	const [searchResults, setSearchResults] = useState({ cats: [], prds: [] });

	useEffect(() => {
		if (width <= 1024) {
			dispatch(closeDesktopMenuAction());
		}
	}, [width]);

	useEffect(() => {
		// FETCHING MENU ITEMS (MAN, WOMAN, CHILD)
		api_getMainMenu()
			.then(json => {
				setMenuItems(json.items);
			})
			.catch(error => {
				console.error(error);
			});
	}, []);

	useEffect(() => {
		if (!showDesktopMenu) {
			dispatch(setActiveCategoryId(null));
		}
	}, [eurl]);

	const handleSelectMenuItem = (id, code) => {
		if (activeCategoryId !== id) {
			if (code == 'BRANDS') {
				setHasSelectedMenuItem(false);
				setshowTitle(false);
				dispatch(setActiveCategoryId(id));
				dispatch(openDesktopMenuAction());
			} else if (code == 'SPORTS') {
				setHasSelectedMenuItem(false);
				setshowTitle(true);
				dispatch(setActiveCategoryId(id));
				dispatch(openDesktopMenuAction());
			} else if (code == 'OFFERS') {
				setHasSelectedMenuItem(false);
				setshowTitle(true);
				dispatch(setActiveCategoryId(id));
				dispatch(openDesktopMenuAction());
			} else if (code == 'CLEARANCE') {
				setshowTitle(false);
				dispatch(setActiveCategoryId(null));
				dispatch(closeDesktopMenuAction());
			} else {
				setHasSelectedMenuItem(true);
				setshowTitle(false);
				dispatch(setActiveCategoryId(id));
				dispatch(openDesktopMenuAction());
			}
		} else {
			setshowTitle(false);
			dispatch(setActiveCategoryId(null));
			dispatch(closeDesktopMenuAction());
		}
	};

	const handleCloseMenu = () => {
		dispatch(setActiveCategoryId(null));
		dispatch(closeDesktopMenuAction());
	};

	const handleOpenCart = () => {
		if (width > 900) {
			dispatch(openCartWidgetAction(true));
			document.body.classList.add('cart-open');
		} else {
			navigate('/cart');
		}
	};

	const handleClickProfile = e => {
		e.preventDefault();
		if (width <= 1150) {
			navigate('/navigation');
		} else {
			navigate('/profile');
		}
	};

	return (
		<>
			{showLogin && <Login />}
			{showChechoutLoginModal && <CheckoutStep1 />}

			<InfoMessage className="topbar-message" code="top-message" hasCloseButton={false} />
			<ChangeLanguage styles={{ color: '#fff', position: 'absolute', right: '20px', top: '5px' }} />

			<header id="default-header">
				<ApplicationMode />
				<div className="content-wrapper">
					{/* Show on tablet and mobile */}
					<div id="burger-btn" onClick={() => dispatch(openMobileMenuAction())}>
						<img src={menu} alt="Menu Icon" />
					</div>
					{/* Show on tablet and mobile */}

					<div id="logo">
						<Link to="/" onClick={handleCloseMenu}>
							<img src={'/images/sportistas-logo-md.png'} alt="Sportistas" />
						</Link>
					</div>

					<div className="menu">
						<ul>
							{menuItems
								.filter(x => x.pid == undefined)
								.map((item, index) => {
									if (item.cd == 'CLEARANCE') {
										return (
											<li
												key={index}
												onClick={() => handleSelectMenuItem(item.id, item.cd)}
												className="menu-item hover-underline-animation">
												<Link to={item.nu}>{item.tl}</Link>
											</li>
										);
									} else {
										return (
											<li
												key={index}
												className={`menu-item hover-underline-animation ${
													item.id == activeCategoryId && 'menu-item-active'
												}`}
												onClick={() => handleSelectMenuItem(item.id, item.cd)}>
												{item.tl}
											</li>
										);
									}
								})}
						</ul>
					</div>

					<div className="header-options">
						<Search
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							searchResults={searchResults}
							setSearchTransition={setSearchTransition}
							setSearchResults={setSearchResults}
							loading={searchResultsLoading}
							setLoading={setSearchResultsLoading}
						/>

						<div id="user-login" onClick={handleCloseMenu}>
							{UserSession.isAuthenticated() ? (
								<Link to="/profile" onClick={handleClickProfile} className="active">
									<img src={userIcon} alt="LOGIN" />
								</Link>
							) : (
								<Link to="/login" className="active">
									<button type="button" className="active">
										<img src={userIcon} alt="LOGIN" />
									</button>
								</Link>
							)}
						</div>
						<div id="view-wishlist" onClick={handleCloseMenu}>
							{UserSession.isAuthenticated() ? (
								<Link to="/wishlists" className="active">
									<img src={wishlistIcon} alt="WISHLIST" />
								</Link>
							) : (
								<Link to="/wishlist" className="active">
									<img src={wishlistIcon} alt="WISHLIST" />
								</Link>
							)}
						</div>
						<div id="view-cart" onClick={handleCloseMenu}>
							<button className="active" onClick={() => handleOpenCart()}>
								<img src={cartIcon} alt="CART" />
								<span>{headerCartQuantity}</span>
							</button>
						</div>
					</div>
				</div>

				{!showDesktopMenu && (
					<div>
						<InfoMessage className="underheader-message" code="underheader-message" hasCloseButton={true} />
					</div>
				)}

				{showDesktopMenu && (
					<MenuDesktop
						hasSelectedMenuItem={hasSelectedMenuItem}
						menuItems={menuItems}
						activeCategoryId={activeCategoryId}
						showTitle={showTitle}
					/>
				)}

				{showMobileMenu && <MenuMobile menuItems={menuItems} activeCategoryId={activeCategoryId} />}
				<SearchScreen
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
					searchTransition={searchTransition}
					setSearchTransition={setSearchTransition}
					loading={searchResultsLoading}
					setLoading={setSearchResultsLoading}
				/>
			</header>

			{openCartWidget && <CartWidget />}
		</>
	);
};

export default Header;
