import React, { useEffect, useState } from 'react';

import { formatAmountValue } from 'common/helper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CartItem from '../cart/CartItem.js';

import ShopCart from 'backend/shop_cart';

import { api_composeCartTotals } from 'backend/api_calls';
import UserSession from 'backend/user_session';
import AppliedDiscountsModal from 'components/checkout/OrderSynopsis/AppliedDiscountsModal.js';
import useApplication from 'components/layout/application/useApplication.js';
import closeicon from 'images/close-button.svg';
import infoIcon from 'images/info-identifier.svg';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loyaltyPointChange, setOrderTotals } from 'store/actions';
import { headerCartQuantityAction, openCartWidgetAction } from '../../store/cart/actions.js';
import Button from './Button.js';
import { notifyError } from './ToastMessages.js';

const CartWidget = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [discountsModalOpen, setDiscountsModalOpen] = useState(false);
	const [cartItems, setCartItems] = useState(ShopCart.getItems());
	const orderTotals = useSelector(state => state.checkoutReducer.orderTotals);
	const coupons = useSelector(state => state.checkoutReducer.coupons);
	const giftcards = useSelector(state => state.checkoutReducer.giftcards);
	const selectedLoylatyPoints = useSelector(state => state.checkoutReducer.selectedLoylatyPoints);

	const firstUpdate = useRef(true);

	// SPORTISTAS APPLICATION RELATED
	const { removeInvalidProducts } = useApplication();
	const isApplicationUser = useSelector(state => state.applicationReducer.isApplicationUser);
	const hasAcceptedApplicationTerms = useSelector(state => state.applicationReducer.hasAcceptedTerms);
	const mode = useSelector(state => state.applicationReducer.mode);

	const closeCart = () => {
		document.body.classList.remove('cart-open');
		dispatch(openCartWidgetAction(false));
	};

	const goCheckout = e => {
		const cartItemErrors = document.getElementsByClassName('cart-item-error');

		if (cartItemErrors.length > 0) {
			notifyError(t('cart_fix_errors_prompt'));
		} else if (!ShopCart.isEmpty()) {
			navigate('/checkout-step-1');
			closeCart();
		}
	};

	useEffect(() => {
		if (cartItems.length > 0) {
			const applicationMeta = {
				isApplicationUser: isApplicationUser,
				hasAcceptedApplicationTerms: hasAcceptedApplicationTerms,
				mode: mode
			};

			api_composeCartTotals(cartItems, null, coupons, giftcards, selectedLoylatyPoints, applicationMeta)
				.then(json => {
					dispatch(setOrderTotals(json));
				})
				.catch(err => {
					removeInvalidProducts();
				});
		}

		dispatch(headerCartQuantityAction(ShopCart.getTotalQuantity()));

		if (!firstUpdate.current && UserSession.isAuthenticated()) {
			// api_updateCustomerDBCart({ items: cartItems });
		}

		firstUpdate.current = false;
	}, [cartItems, mode]);

	// useEffect(() => {
	// 	if (orderTotals.amt >= 0) {
	// 		viewCartGTAG4Event(ShopCart.getItemsForTagManager(), orderTotals.amt);
	// 	}
	// }, [orderTotals.amt]);

	if (cartItems.length == 0) {
		return (
			<div id="cart-widget" className="flex-col">
				<div className="transparent-side" onClick={() => closeCart()}></div>
				<div className="cart-widget">
					<div className="empty-content">
						{t('no_items_in_cart')}
						<div onClick={() => closeCart()} className="cart-close-icon">
							<img src={closeicon} alt="close menu" />
						</div>
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<div id="cart-widget" className="flex-col">
				<div className="transparent-side" onClick={() => closeCart()}></div>
				<div className="cart-widget">
					<div className="head-account">
						<h2>
							{t('your_cart')} ({cartItems.length})
						</h2>
						<div onClick={() => closeCart()} className="cart-close-icon">
							<img src={closeicon} alt="close menu" />
						</div>
					</div>
					<div className="cart-items-container">
						<div className="cart-items">
							{cartItems.map((item, index) => (
								<CartItem cartItem={item} key={item.pid + '_' + item.dimension} setCartItems={setCartItems} />
							))}
						</div>
					</div>
					<div className="sticky-cart-total">
						<div className="total flex-col">
							<div className="name">{orderTotals.dsct > 0 ? t('order_total_start') : t('order_total_final')}</div>
							<div className="amount">{formatAmountValue(orderTotals.amt)}</div>
						</div>
						{orderTotals.dsct > 0 && (
							<>
								<div className="total discount flex-col" onClick={() => setDiscountsModalOpen(true)}>
									<div className="name">{t('order_total_discount')}</div>
									<span className="details-indicator">
										<img src={infoIcon} alt="Info" />
									</span>
									<div className="amount">{formatAmountValue(orderTotals.dsct)}</div>
								</div>
								<div className="total flex-col">
									<div className="name">{t('order_total_final')}</div>
									<div className="amount">{formatAmountValue(orderTotals.gamt)}</div>
								</div>
							</>
						)}
						<div className="buttons-cart">
							<Button
								color="secondary"
								outline={true}
								actionOnClick={() => {
									closeCart();
									dispatch(loyaltyPointChange(0));
									navigate('/cart');
								}}>
								{t('go_to_cart')}
							</Button>
							<Button color="secondary" actionOnClick={e => goCheckout(e)}>
								{t('go_to_checkout')}
							</Button>
						</div>
						{/* <ul className="cart-ship-info grid-col">
                            {deliveryFee(orderTotals.amt)}
                        </ul> */}
					</div>
				</div>
			</div>

			<AppliedDiscountsModal
				appliedDiscounts={orderTotals.adi}
				open={discountsModalOpen}
				setOpen={setDiscountsModalOpen}
			/>
		</>
	);
};

export default CartWidget;
