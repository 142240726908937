import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeCookiesAction, consentCookiesAction, openCookiesAction } from 'store/cookies/actions';
import CookiesConsent from '../../backend/cookies_consent';

import arrowRight from 'images/arrow-right.svg';
import closeicon from 'images/close-icon.svg';
import cookieslogo from 'images/cookies-icon.svg';
import { openCouponPopupAction } from 'store/actions';
import Button from './Button';

const CookiesBanner = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const showBanner = useSelector(state => state.cookiesReducer.showCookies);
	const [showOptions, setShowOptions] = useState(false);
	const [activeOption, setActiveOption] = useState(0);
	const [necessary, setNecessary] = useState(true);
	const [preferences, setPreferences] = useState(true);
	const [marketing, setMarketing] = useState(true);
	const [performance, setPerformance] = useState(true);

	useEffect(() => {
		if (CookiesConsent.showBanner()) {
			setTimeout(() => {
				dispatch(openCookiesAction());
			}, 2000);
		} else {
			dispatch(openCouponPopupAction());
		}
	}, []);
	const handleAccept = () => {
		CookiesConsent.initWithJson(true, true, true, true);
		dispatch(consentCookiesAction({ necessary: true, preferences: true, marketing: true, performance: true }));
		dispatch(closeCookiesAction());
		dispatch(openCouponPopupAction());
		document.body.classList.remove('overflow');

		const event = new CustomEvent('cookies_changed', {
			detail: { message: 'Cookies Changed' }
		});
		window.dispatchEvent(event);
	};

	// const handleReject = () => {
	// 	CookiesConsent.initWithJson(true, false, false, false);
	// 	dispatch(consentCookiesAction({ necessary: true, preferences: false, marketing: false, performance: false }));
	// 	dispatch(closeCookiesAction());
	// dispatch(openCouponPopupAction());
	// 	document.body.classList.remove('overflow');
	// };

	const handleSaveChoices = () => {
		CookiesConsent.initWithJson(true, preferences, marketing, performance);
		dispatch(consentCookiesAction({ necessary, preferences, marketing, performance }));
		dispatch(closeCookiesAction());
		dispatch(openCouponPopupAction());
		document.body.classList.remove('overflow');

		const event = new CustomEvent('cookies_changed', {
			detail: { message: 'Cookies Changed' }
		});
		window.dispatchEvent(event);
	};

	const handleSetActiveOption = option => {
		if (option == activeOption) {
			setActiveOption(0);
			return;
		}

		setActiveOption(option);
	};

	return (
		<div id="cookies-container" style={{ visibility: showBanner ? 'unset' : 'hidden' }}>
			<div className="backdrop"></div>
			<div id="cookie-banner">
				<div className="content-wrapper">
					<div className="header">
						<div className="cookies-logo">
							<img src={cookieslogo} alt="shipping" />
						</div>
						<div className="title">{t('cookies_summary_title')}</div>
						<div onClick={() => handleSaveChoices()} className="close-icon">
							<img src={closeicon} alt="shipping" />
						</div>
					</div>
					<div className="content">
						{!showOptions ? (
							<p className="explaination">{t('cookies_summary')}</p>
						) : (
							<div className="categories">
								<div className="category">
									<div className="select">
										<input type="checkbox" id="necessary" checked={true} disabled={true} />
										<label htmlFor="necessary" className={`${activeOption == 1 && 'active'}`}>
											{t('cookies_categories_necessary')}
										</label>
										<span onClick={() => handleSetActiveOption(1)}>
											<img src={arrowRight} alt="open necessary cookies summary" />
										</span>
									</div>
									{activeOption == 1 && <p>{t('cookies_categories_necessary_content')}</p>}
								</div>
								<div className="category">
									<div className="select">
										<input
											type="checkbox"
											id="statistics"
											checked={performance}
											onChange={() => setPerformance(!performance)}
										/>
										<label htmlFor="statistics" className={`${activeOption == 2 && 'active'}`}>
											{t('cookies_categories_statistics')}
										</label>
										<span onClick={() => handleSetActiveOption(2)}>
											<img src={arrowRight} alt="open statistics cookies summary" />
										</span>
									</div>
									{activeOption == 2 && <p>{t('cookies_categories_statistics_content')}</p>}
								</div>
								<div className="category">
									<div className="select">
										<input
											type="checkbox"
											id="preferences"
											checked={preferences}
											onChange={() => setPreferences(!preferences)}
										/>
										<label
											htmlFor="preferences"
											className={`${activeOption == 3 && 'active'}`}
											onClick={() => handleSetActiveOption(3)}>
											{t('cookies_categories_preferences')}
										</label>
										<span onClick={() => handleSetActiveOption(3)}>
											<img src={arrowRight} alt="open preferences cookies summary" />
										</span>
									</div>
									{activeOption == 3 && <p>{t('cookies_categories_preferences_content')}</p>}
								</div>
								<div className="category">
									<div className="select">
										<input
											type="checkbox"
											id="marketing"
											checked={marketing}
											onChange={() => setMarketing(!marketing)}
										/>
										<label
											htmlFor="marketing"
											className={`${activeOption == 4 && 'active'}`}
											onClick={() => handleSetActiveOption(4)}>
											{t('cookies_categories_marketing')}
										</label>
										<span onClick={() => handleSetActiveOption(4)}>
											<img src={arrowRight} alt="open marketing cookies summary" />
										</span>
									</div>
									{activeOption == 4 && <p>{t('cookies_categories_marketing_content')}</p>}
								</div>
							</div>
						)}
					</div>
					{/* <p className="readmore">
                        <a href="#" target="_blank">Προβολή Λεπτομεριών</a>
                    </p> */}
					<div className="actions-wrapper">
						<div className="action-buttons">
							{!showOptions ? (
								<Button outline={true} color="secondary" actionOnClick={() => setShowOptions(true)}>
									{t('cookies_customize')}
								</Button>
							) : (
								<Button outline={true} color="secondary" actionOnClick={handleSaveChoices}>
									{t('cookies_customize_save')}
								</Button>
							)}
							<Button color="secondary" actionOnClick={handleAccept}>
								{t('cookies_accept')}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookiesBanner;
