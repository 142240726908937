import Button from 'components/common/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setShowNotification } from 'store/actions';
import useApplication from '../useApplication';

const InvalidProductsInCart = ({ changeMode }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { removeInvalidProducts } = useApplication();

	return (
		<>
			<div className="header">
				<div className="title">
					{t('application_invalid_products_in_cart')}
					<br />
				</div>
			</div>
			<div className="body"></div>
			<div className="footer">
				<Button
					color="secondary"
					className="mode-actions"
					actionOnClick={() => {
						dispatch(setShowNotification(false));
					}}>
					SHOP FOR FRIENDS
				</Button>
				<Button
					color="primary"
					className="mode-actions"
					actionOnClick={() => {
						dispatch(setShowNotification(false));
						removeInvalidProducts();
						changeMode();
					}}>
					SHOP FOR ME
				</Button>
			</div>
		</>
	);
};

InvalidProductsInCart.propTypes = {
	changeMode: PropTypes.func
};

export default InvalidProductsInCart;
