import { api_searchCategories, api_searchProducts } from 'backend/api_calls';
import useDebounce from 'components/hooks/useDebounce';
import useWindowSize from 'components/hooks/useWindowSize';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeDesktopMenuAction, setActiveCategoryId } from 'store/actions';
import closeIcon from '../../../images/close-icon.svg';
import searchIcon from '../../../images/search-icon.svg';

function SearchBar({
	searchTerm,
	setSearchTerm,
	setSearchResults,
	setSearchTransition,
	showOnSearchScreen,
	setLoading
}) {
	const { t } = useTranslation();
	const { width } = useWindowSize();
	const dispatch = useDispatch();
	const showDesktopMenu = useSelector(state => state.layoutReducer.showDesktopMenu);
	const [isFocused, setIsFocused] = useState(false);
	const debouncedSearchTerm = useDebounce(searchTerm, 700);

	let searchParameters = {
		enrich: false,
		keyword: '',
		pageIndex: 0,
		pageSize: 400,
		// pageSize: 30,
		categoryIds: '',
		status: 0
	};

	useEffect(() => {
		if (debouncedSearchTerm) {
			if (showDesktopMenu) {
				dispatch(closeDesktopMenuAction());
				dispatch(setActiveCategoryId(null));
			}

			searchParameters.keyword = searchTerm;
			setLoading(true);

			Promise.all([
				api_searchCategories(searchParameters.keyword)
					.then(json => {
						return json.cats;
					})
					.catch(error => {
						console.log(error);
					}),

				api_searchProducts(searchParameters)
					.then(json => {
						return json.prds;
					})
					.catch(error => {
						console.log(error);
					})
			]).then(data => {
				setSearchResults({ cats: data[0], prds: data[1] });
				setLoading(false);
			});
		} else {
			setSearchResults({ cats: [], prds: [] });
			setLoading(false);
		}
	}, [debouncedSearchTerm]);

	const handleShowSearchScreen = () => {
		if (width <= 1200) {
			setSearchTransition(true);
			return;
		}
	};

	const handleChangeSearch = value => {
		if (value.trim()) {
			setLoading(true);
		}

		setSearchTerm(value);

		if (value) {
			setSearchTransition(true);
			return;
		}

		if (width > 1200) {
			setSearchTransition(false);
		}
	};

	const handleDeleteSearchTerm = () => {
		if (width > 1200) {
			setSearchTerm('');
			setSearchTransition(false);
		} else {
			setSearchTransition(false);
		}
	};

	return (
		<div className="search">
			<input
				value={searchTerm}
				onChange={e => handleChangeSearch(e.target.value)}
				type="text"
				placeholder={t('header_search_input_placeholder')}></input>

			<button>
				{searchTerm.length > 0 || showOnSearchScreen ? (
					<img
						src={closeIcon}
						style={{ transform: 'scale(0.6)' }}
						alt="Close icon"
						onClick={() => handleDeleteSearchTerm()}
					/>
				) : (
					<img src={searchIcon} alt="Search icon" onClick={() => handleShowSearchScreen()} />
				)}
			</button>
		</div>
	);
}

SearchBar.propTypes = {
	searchTerm: PropTypes.string,
	setSearchTerm: PropTypes.func,
	setSearchResults: PropTypes.func,
	setSearchTransition: PropTypes.func,
	showOnSearchScreen: PropTypes.bool,
	setLoading: PropTypes.func
};

export default SearchBar;
