import { GoogleOAuthProvider } from '@react-oauth/google';
import { api_getProfile } from 'backend/api_calls';
import UserSession from 'backend/user_session';
import { userInfoGTAG4Event } from 'common/gtag4';
import BreadcrumbsSimple from 'components/common/BreadcrumbSimple';
import Button from 'components/common/Button';
import { notifyError } from 'components/common/ToastMessages';
import useWindowSize from 'components/hooks/useWindowSize';
import useApplication from 'components/layout/application/useApplication';
import FacebookLoginButton from 'components/login/FacebookLoginButton';
import GoogleLoginButton from 'components/login/GoogleLoginButton';
import GuestForm from 'components/login/GuestForm';
import LoginForm from 'components/login/LoginForm';
import RegisterForm from 'components/login/RegisterForm';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearAuthMessagesAction, setHasAcceptedApplicationTerms, setIsApplicationUser } from 'store/actions';

const Login = ({ redirectOnLogin }) => {
	const { t } = useTranslation();
	const isProduction = process.env.REACT_APP_ENV === 'production';
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const { applicationIsEnabledForSite, checkIsApplicationUser, checkHasAcceptedTerms } = useApplication();
	const { width } = useWindowSize();
	const successMessage = useSelector(state => state.authReducer.message);
	const authError = useSelector(state => state.authReducer.error);

	const [activeTab, setActiveTab] = useState(1);
	const [registerFormActive, setRegisterFormActive] = useState(false);

	const [pathToRedirect, setPathToRedirect] = useState('');

	useEffect(() => {
		if (redirectOnLogin) {
			setPathToRedirect(redirectOnLogin);
		} else {
			if (width <= 1150) {
				setPathToRedirect('/navigation');
			} else {
				setPathToRedirect('/profile');
			}
		}
	}, [redirectOnLogin, width]);

	useEffect(() => {
		if (location.pathname == '/login') {
			if (UserSession.isAuthenticated()) {
				navigate('/profile');
			}

			setRegisterFormActive(true);
		}

		const action = new URLSearchParams(location.search).get('action');
		if (action === 'register') {
			setActiveTab(2);
		}
	}, []);

	useEffect(() => {
		if (successMessage.length > 0) {
			setTimeout(() => {
				dispatch(clearAuthMessagesAction());
			}, 2000);

			fetchApplicationData();
		}

		if (authError.length > 0) {
			notifyError(authError, () => {
				setTimeout(() => {
					dispatch(clearAuthMessagesAction());
				}, 2000);
			});
			return;
		}
	}, [successMessage, authError]);

	const fetchApplicationData = async () => {
		let redirectTo = pathToRedirect;

		try {
			const profile = await api_getProfile();
			userInfoGTAG4Event(profile);

			if (applicationIsEnabledForSite()) {
				const isApplication = await checkIsApplicationUser();
				const hasAcceptedTerms = await checkHasAcceptedTerms();

				dispatch(setIsApplicationUser(isApplication));
				dispatch(setHasAcceptedApplicationTerms(hasAcceptedTerms));

				if (isApplication && !hasAcceptedTerms) {
					redirectTo = '/application';
				}
			}

			navigate(redirectTo); // Redirect here
		} catch (err) {
			console.log(err);
			navigate(redirectTo); // Redirect here in case of an error
		}
	};

	return (
		<div id="login-page">
			<BreadcrumbsSimple currentName={t('footer_links_title_second')} />

			<div className="content-wrapper">
				<div className="login-page-container">
					<div className="header">
						<h2 className={`${activeTab === 1 && 'active active-first'}`} onClick={() => setActiveTab(1)}>
							{t('sign_in')}
						</h2>
						<h2 className={`${activeTab === 2 && 'active active-second'}`} onClick={() => setActiveTab(2)}>
							{t('register')}
						</h2>
					</div>

					{activeTab == 1 ? (
						<div className="login-tab">
							<LoginForm />

							<div className="login-alter">
								<span>{t('sign_in_alternative')}</span>
								<div className="social-providers">
									<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
										<GoogleLoginButton />
									</GoogleOAuthProvider>
									{!isProduction && <FacebookLoginButton />}
								</div>
							</div>
						</div>
					) : (
						<div className="register-tab">
							{!registerFormActive ? (
								<div className="options">
									<GuestForm redirectOnLogin={redirectOnLogin} />

									<div className="create-account-option">
										<p>{t('register_info_title')}</p>
										<ul>
											<li>{t('register_info_1')}</li>
											<li>{t('register_info_2')} </li>
											<li>{t('register_info_3')}</li>
										</ul>
										<Button color="secondary" actionOnClick={() => setRegisterFormActive(true)}>
											{t('create_an_account')}
										</Button>
									</div>
								</div>
							) : (
								<div className="register-form">
									<RegisterForm setRegisterFormActive={setRegisterFormActive} />

									<div className="login-alter">
										<span>{t('register_alternative')}</span>
										<div className="social-providers">
											<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
												<GoogleLoginButton />
											</GoogleOAuthProvider>
											{!isProduction && <FacebookLoginButton />}
										</div>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Login;
